import { useStaticQuery, graphql } from "gatsby"

export const usePrices = () => {
    const { prices } = useStaticQuery(
        graphql`
      query AllStripePriceQuery {        
        prices:   allStripePrice(
            filter: {billing_scheme: {eq: "per_unit"}, active: {eq: true}, type: {eq: "one_time"}}
            sort: { fields: [unit_amount] }
        ) {
            nodes {
                active
                billing_scheme
                currency
                id
                description: nickname
                type
                unit_amount
                unit_amount_decimal
                product {
                  id
                  name
                }
            }
        }
      }
    `
    )
    return prices?.nodes
}