import * as React from "react"
import Seo from "../../components/seo"
import BrandHeading from "../../components/brand-heading";
import { DietaryRequirementsSection } from "../../components/dietary-options";
import { useCelebrationCakes } from "../../hooks/use-celebration-cakes";
import { usePrices } from "../../hooks/use-prices";
import { Link } from "gatsby";
import Cake from "../../components/cake";

export default function CelebrationCakes() {
  const prices = usePrices();
  const products = useCelebrationCakes().sort((x, y) => x.metadata.sort - y.metadata.sort);
  const cakes = products.map(cake => {
    const cakePrices = prices.filter(x => x.product.id === cake.id);
    cake = {
      ...cake
      , code: cake.metadata.code
      , imageFile: cake.localImage[0]
      , options: cakePrices.map(x => { return { id: x.id, size: x.description, price: (x.unit_amount) / 100 }; })
    }
    return <Link key={cake.code} to={`/cakes/${cake.metadata.subType}/${cake.code}`}>
      <Cake cake={cake} />
    </Link>
  })
  return (
    <>
      <Seo title="Celebration Cakes" />
      <BrandHeading title="celebration cakes" />
      <section>
        <DietaryRequirementsSection className="mb-10 text-center text-sm md:text-base" />
        <div className="flex flex-wrap justify-evenly">
          {cakes}
        </div>
      </section>
    </>
  )
}
